@font-face {
    font-family: 'Inter';
    src: url('../Assets/Fonts/Inter-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('../Assets/Fonts/Inter-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('../Assets/Fonts/OpenSans-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('../Assets/Fonts/OpenSans-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }

.all-black {
  background-color: #000000;
  color: #FFFFFF;
}

.entire-svm {
    width: 80%;
    margin: 0 auto;
}

.svm-banner {
    max-height: 50vh;
    margin: 0 auto;
    justify-self: center;
    display: flex;
    padding-top: 1.5em;
    padding-bottom: 4em;
}

@media (max-width: 600px) {
  .svm-banner {
      max-width: 100%;
      padding-bottom: 2em;
  }
}

.svm-logo {
  width: 4em;
  padding-bottom: 1.5em;
  @media (min-width: 800px) {
    padding-bottom: 0;
  }
}

.svm-poster {
  width: 100%;
  transition: transform .2s;
}

.svm-poster:hover {
  transform: scale(0.98);
}