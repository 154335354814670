@font-face {
    font-family: 'Inter';
    src: url('../../Assets/Fonts/Inter-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('../../Assets/Fonts/Inter-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }

  .entire-container-header {
    font-size: 1em;
    width: 80%;
    margin: 0 auto;
    padding-top: 1.7em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1em;
}

.arrow-header {
    align-self: flex-start;
}

.logo {
    justify-self: left;
}

.alan-logo {
    width: 14em;
    height: auto;
    transition: opacity 0.4s ease;
}
.alan-logo:hover {
    opacity: 0.5; 
}

.no-wrapping {
    display: flex;
}

.pages {
    font-family: 'Inter';
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    width: 45%;
}

@media (max-width: 900px) {
    .pages {
        width: 50%;
    }
}

@media (max-width: 600px) {
    .pages {
        flex-direction: column;
        align-items: flex-end;
        font-size: 0.75em;
    }
}

.page {
    text-decoration: none;
    color: #929292;
    font-size: 0.93rem;
    padding-right: 0.2em;
}

.page:hover {
    color: #FFFFFF;
    transition: color 0.4s ease;
}