@font-face {
    font-family: 'Inter';
    src: url('../../Assets/Fonts/Inter-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('../../Assets/Fonts/Inter-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('../../Assets/Fonts/OpenSans-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('../../Assets/Fonts/OpenSans-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }

.Footer {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 3em;
}

@media (max-width: 600px) {
  .Footer {
    flex-direction: column;
  }
}

.signature {
    width: 12em;
}

.design-line {
    color: white;
    padding-bottom: 1em;
    font-family: 'Open Sans';
}

.footer-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

@media (max-width: 600px) {
  .footer-links {
    display: none;
  }
}

.footer-page {
    font-family: 'Inter';
    text-decoration: none;
    color: #929292;
    transition: color 0.4s ease;
}

.footer-page:hover {
    color: #FFFFFF;
}