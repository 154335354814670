@font-face {
  font-family: 'Inter';
  src: url('../../Assets/Fonts/Inter-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../Assets/Fonts/Inter-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../Assets/Fonts/OpenSans-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../Assets/Fonts/OpenSans-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
  font-weight: normal;
  font-style: normal;
}

a.card-link {
    text-decoration: none;
  }

.projects-area {
  width: 70%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px) {
  .projects-area {
      flex-direction: column;
  }
}

.project-image-container {
  flex-basis: 63%;
}

.project-screen {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
}

@media (max-width: 600px) {
  .project-screen {
    margin-bottom: 1rem;
  }
}

.home-project-text {
  flex-basis: 37%;
  padding-left: 4em;
}

@media (max-width: 600px) {
  .home-project-text {
      padding-left: 0;
  }
}

.titular {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .titular-arrow {
      display: none;
  }
}

.proj-name {
  color: white;
  font-size: 2.4rem;
  font-weight: 700;
  padding-bottom: 0.1em;
  font-family: 'Open Sans';
  transition: color 0.4s ease;
}

.proj-name:hover {
  color: #A8D5FF;
}

.proj-description {
  font-family: 'Inter';
  color: #929292 !important;
  font-size: 1.25rem;
}

.proj-details {
  font-family: 'Inter';
  color: rgba(255, 255, 255, 0.79);
  padding-top: 0.8em;
  font-size: 1.125rem;
}

.grey-line-proj {
  width: 85%;
  margin: 0 auto;
  border-top: 0.5px solid #7A7A7A;
  margin-top: 8em;
  height: 10em;
}

@media (max-width: 600px) {
  .grey-line-proj {
    margin-top: 4em;
    height: 5em;
  }
}

.grey-line-proj-last {
  width: 85%;
  margin: 0 auto;
  border-top: 0.5px solid #7A7A7A;
  margin-top: 8em;
  height: 4em;
}

@media (max-width: 600px) {
  .grey-line-proj-last {
    margin-top: 4em;
    height: 4em;
  }
}