
.thing {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    padding-top: 2.5em;
    font-size: 1.5em;
    font-family: 'Circular';
}

.things {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    padding-top: 2.5em;
    font-size: 1.5em;
    font-family: 'Circular';
    text-decoration: none;
    color: black;
}

.things:hover {
    color: #FFCE43;
}

.thing-image {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    padding-top: 2.5em;
    width: 25em;
    height: auto;
}