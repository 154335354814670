@font-face {
    font-family: 'Inter';
    src: url('../../Assets/Fonts/Inter-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('../../Assets/Fonts/Inter-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('../../Assets/Fonts/OpenSans-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('../../Assets/Fonts/OpenSans-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }

  .entire-container-alan {
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    padding-top: 1.5em;
}

@media (max-width: 600px) {
    .entire-container-alan {
        flex-direction: column-reverse;
        padding-top: 0;
    }
}

.me-stuff {
    flex-basis: 55%;
    margin-bottom: 1em;
}

.bridge-stuff {
    flex-basis: 45%;
}

.actual-bridge {
    width: 100%;
}

@media (max-width: 600px) {
    .actual-bridge {
        margin-bottom: 3rem;
    }
}

.big-name {
    color: #FFFFFF;
    font-size: 5rem;
    font-weight: 700;
    font-family: 'Open Sans';
}

@media (max-width: 600px) {
    .big-name {
        font-size: 4rem;
        margin-bottom: 2rem;
        line-height: 100%;
    }
}

.under-name {
    font-family: 'Inter';
    color: rgba(255, 255, 255, 0.79);
    font-size: 2.3rem;
    line-height: 150%;
    margin-bottom: 3rem;
}

@media (max-width: 600px) {
    .under-name {
        display: none;
    }
}

.location {
    font-weight: 700;
    color: #FFFFFF;
}

.alan-arrow {
    vertical-align: top;
    padding-top: 0.5em;
}

.alan-positions {
    font-family: 'Inter';
    color: #FFFFFF;
    font-size: 1rem;
    line-height: 170%;
}

.position-tags {
    text-decoration: none;
    color: #929292;
}

.position-tags:hover {
    color: #FFFFFF;
    transition: color 0.4s ease;
}

.epic-logo {
    position: relative;
    top: 12px;
    height: 1.2em;
    justify-content: flex-end;
}
