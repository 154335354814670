/* 
  .entire-projects {
    width: 95%;
    margin: 0 auto;
    justify-content: center;
    display: grid;
    @media (min-width: 800px) {
      grid-template-columns: 50% 50%;
    }
    grid-template-columns: 100%;
    column-gap: 5%;
    row-gap: 5%;
  } */

  .entire-projects {
    width: 100%;
  }