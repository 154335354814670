.projects-page {
    background-color: black;
}

.projects-space {
    height: 4em;
}

/* .entire-projects-page-container {
    width: 80%;
    margin: 0 auto;
}

.temp {
    height: 5em;
}

.between-projects {
    height: 4em;
} */