@font-face {
    font-family: 'Inter';
    src: url('../Assets/Fonts/Inter-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('../Assets/Fonts/Inter-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('../Assets/Fonts/OpenSans-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('../Assets/Fonts/OpenSans-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }

  .about-black {
    background-color: #000000;
    color: white;
  }

.entire-about-container {
    width: 85%;
    margin: 0 auto;
}

.name-blue {
    width: 90%;
    margin: 0 auto;
}

.skillz {
    max-height: 1.5em;
}

.technologiez {
    max-height: 1.85em;
}

.name-about {
    padding-top: 8em;
    width: 40%;
  }

@media (max-width: 600px) {
    .name-about {
        padding-top: 4em;
        width: 100%;
      }
}

.position-about {
    font-family: 'Inter';
    font-size: 2em;
    width: 90%;
    margin: 0 auto;
}

.paragraphs-container {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 0 auto;
}

.paragraphs {
    font-family: 'Inter';
    font-size: 1.2em;
    line-height: 1.3em;
    font-weight: 100;
    width: 90%;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .paragraphs {
        font-size: 1.em;
        line-height: 1.4em;
    }
}

.skills-technologies {
    margin-left: 2em;
    line-height: 170%;
}

@media (max-width: 600px) {
    .skills-technologies {
        margin-left: 0;
    }
}

.bolded {
    font-weight: 700;
    font-size: 1.3em;
}

.a-tags {
    text-decoration: none;
    color: white;
    font-weight: 700;
    transition: color 0.4s ease;    
}

.a-tags:hover {
    color: #A8D5FF;
}

.paragraphs-bold {
    font-weight: 700;
}

.paragraph-enter {
    height: 1.4em;
}

.grey-line-about {
    border-top: 1px solid #CECECE;
    margin-top: 5em;
    margin-bottom: 4em;
}

@media (max-width: 600px) {
    .grey-line-about {
        margin-top: 3em;
        margin-bottom: 3em;
    }
}

.contact-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 600px) {
    .contact-container {
        flex-direction: column;
    }
}

.contact {
    font-size: 1.5em;
    font-weight: 700;
    width: 10em;
    font-family: 'Open Sans';
}

.icons-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: auto;
}

@media (max-width: 600px) {
    .icons-row {
        width: 80%;
        padding-top: 2em;
    }
}

.icons {
    height: 1.5em;
    padding-right: 4em;
    padding-left: 4em;
}

@media (max-width: 600px) {
    .icons {
        padding-left: 0;
        padding-right: 0;
    }
}

.icons:hover {
    opacity: 0.5;
    transition: opacity 0.4s ease;
}