.Home {
  background-color: black;
}

.first-section {
    margin-bottom: 10em;
  }
  
.section {
    margin-bottom: 5em;
  }

.grey-line-home {
  width: 85%;
  margin: 0 auto;
  border-top: 0.5px solid #7A7A7A;
  margin-top: 7em;
  height: 8em;
}

@media (max-width: 600px) {
  .grey-line-home {
    margin-top: 4em;
    height: 5em;
  }
}