@font-face {
    font-family: 'Inter';
    src: url('../Assets/Fonts/Inter-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('../Assets/Fonts/Inter-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('../Assets/Fonts/OpenSans-Bold.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: url('../Assets/Fonts/OpenSans-Regular.ttf') format('truetype'); /* Adjust the path and font file name accordingly */
    font-weight: normal;
    font-style: normal;
  }

.tcw-black {
    background-color: #000000;
    color: #FFFFFF;
}

.entire-TCW {
    width: 80%;
    margin: 0 auto;
}

.tcw-banner {
    max-height: 50vh;
    margin: 0 auto;
    justify-self: center;
    display: flex;
    padding-top: 1.5em;
    padding-bottom: 4em;
}

@media (max-width: 600px) {
    .tcw-banner {
        max-width: 100%;
        padding-bottom: 2em;
    }
}

.project-details {
    display: flex;
    flex-direction: column;
    @media (min-width: 800px) {
      flex-direction: row;
    }
    width: 90%;
    margin: 0 auto;
  }

.project-basics {
    font-family: 'Inter';
    flex-basis: 50%;
}

.tcw-title {
    font-family: 'Open Sans';
    font-size: 2.3em;
    font-weight: 600;

}

.tcw-type {
    font-family: 'Inter';
    font-size: 1.4em;
    margin-bottom: 0.7em;
}

.tcw-logo {
    width: 4em;
    padding-bottom: 1.5em;
    @media (min-width: 800px) {
    padding-bottom: 0;
  }
}

.top-pairs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 50%;
    padding-top: .5em;
    font-family: 'Inter';
}

.top-pair {
    display: flex;
    flex-direction: row;
}

.top-category {
    flex-basis: 50%;
    font-weight: 600;
}

.top-answer {
    flex-basis: 50%
}

.tcw-grey-line {
    border-top: 1px solid #CECECE;
    margin-top: 7em;
    height: 7em;
}

@media (max-width: 600px) {
    .tcw-grey-line {
        margin-top: 4em;
        height: 4em;
    }
}

.tcw-text-section {
    width: 90%;
    margin: 0 auto;
    font-family: 'Inter';
}

.tcw-section-title {
    font-weight: 600;
    font-size: 2.3em;
    padding-bottom: 1.5em;
}

.paragraph-title {
    font-weight: 600;
    font-size: 1.2em;
}

.paragraph-content {
    font-size: 1em;
}

.paragraph-break {
    height: 2.5em;
}

.tcw-profile-page {
    margin: 0 auto;
    display: flex;
    width: 50%;
    transition: transform .2s;
}

.tcw-home-pages {
    margin: 0 auto;
    display: flex;
    width: 80%;
    transition: transform .2s;
}

@media (max-width: 600px) {
    .tcw-profile-page, .tcw-home-pages {
        width: 100%;
    }
}

.tcw-profile-page:hover {
    transform: scale(0.98);
  }

.tcw-home-pages:hover {
    transform: scale(0.98);
  }

.tcw-bottom {
    height: 7em;
}